import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby";

import TextBlock from '../components/blocks/text-block.js';

import '../css/solutions.css';

const Solutions = ({content, section, textBlock}) => {
  const data = useStaticQuery(graphql`
    query {
      allWpSolution(
        filter: { parentId: { eq: null } }
        sort: { fields: title, order: ASC }
      ) {
        nodes {
          id
          title
          link
          cptSolution {
            invertColor
            mainColor
            shortDescription
            hoverCopy
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const allSolutions = data.allWpSolution.nodes;
  
  const solutions = content.component === 'solutions' ? allSolutions : []; 

  return (      
    <div className="full-width" style={{ backgroundColor: content.backgroundColor}}>
      <div className="container solutionsContainer" style={{ backgroundColor: content.backgroundColor}}>

        <div className="textBlock">
        <TextBlock item={textBlock} section={section} />
        </div>
        
        <div className="new-grid">
        { solutions &&
          solutions.map(solution => {
            const { cptSolution } = solution;
            return (
              <Link to={solution.link} key={solution.id}>
            <div key={solution.id} className={`iconColumn ${cptSolution.invertColor ? 'invertColor' : ''}`}  style={{ borderLeftColor: cptSolution.mainColor, '--hover-bg-color': cptSolution.mainColor}}>

               <div className="front-side">
                <div className="titleWrapper">
                 <div className="iconTitle">{solution.title}</div>
                
                  <div className="iconIcon">{ cptSolution.icon && 
                    <img src={cptSolution.icon.localFile.publicURL} className="flex-img" alt={cptSolution.icon.altText} />
                  }</div>
                </div>
               
                <p className="iconDescription">{cptSolution.shortDescription}</p>
              </div>
              <div className="back-side">
              <p className="iconDescription">{cptSolution.hoverCopy}</p>
              </div>
              </div>
              </Link>
            )
          })
        }
      </div>
    </div>
    </div>
  )
}

export default Solutions