import React from "react"
import { graphql, Link } from "gatsby"

import '../css/solutions.css';

import Icon from '../images/business-icon-min.png';
import Image from '../images/globe-background-min.png';

const Solutions = ({ title, solutions, hideCTA = false }) => { 
  
  // Variables to add to GraphQL

  const bgColor="#F6F8F7";
  const section = "solutions";
  const textBlockHeadline = "Our <strong>Global Logistics</strong><br>Solutions";

  return (
    <div className="container solutionsContainer" style={{ backgroundColor: bgColor}}>
      <div className="text-block">
        <h3 className="super">{section}</h3>
        <h2 className="headline" dangerouslySetInnerHTML={{ __html: textBlockHeadline }} />
        </div>
       
      <div className="new-grid">
        { solutions &&
          solutions.map(solution => {
            const { cptSolution } = solution;
            // console.log(solution);
            return (
              <Link to={solution.link}>
            <div key={solution.id} className={`iconColumn ${cptSolution.invertColor ? 'invertColor' : ''}`}  style={{ borderLeftColor: cptSolution.mainColor, '--hover-bg-color': cptSolution.mainColor}}>

               <div className="front-side">
                <div className="titleWrapper">
                 <div className="iconTitle">{solution.title}</div>
                
                  <div className="iconIcon">{ cptSolution.icon && 
                    <img src={cptSolution.icon.localFile.publicURL} className="flex-img" alt={cptSolution.icon.altText} />
                  }</div>
                </div>
               
                <p className="iconDescription">{cptSolution.shortDescription}</p>
              </div>
              <div className="back-side">
              <p className="iconDescription">{cptSolution.hoverCopy}</p>
              </div>
              </div>
              </Link>
            )
          })
        }
      </div>
      </div>

  )
}

export default Solutions